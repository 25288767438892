/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'


const loginSchema = Yup.object().shape({
    registerId: Yup.string()
        .matches(/^[0-9]+$/, "מספרים בלבד")
        .min(7, 'תעודת זהות לא תקינה')
        .max(9, 'תעודת זהות לא תקינה')
        .required('תעודת זהות חובה'),
    smsCode: Yup.string()
        .matches(/^[0-9]+$/, "מספרים בלבד")
        .min(6, '6 ספרות בלבד')
        .max(6, '6 ספרות בלבד')
        .required('קוד אימות חובה'),
})

const initialValues = {
    registerId: '',
    smsCode: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [showSmsInput, setShowSmsInput] = useState(false)
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    function toggleSmsInput() {

        setShowSmsInput(!showSmsInput)
        if(!showSmsInput) {
            formik.values.smsCode = ''
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await login(values.registerId, values.smsCode)
                if(auth) {
                    setStatus(auth.message)
                    setSubmitting(true)
                    setLoading(false)
                    if (auth.status === 'error' || auth.status === 'user_error') {
                        return
                    } else if (auth && auth.status === 'sms_success') {
                        toggleSmsInput()
                        return
                    } else {
                        saveAuth(auth)
                        const data = await getUserByToken(auth.auth_key)
                        const userData = data.data
                        const user = userData.user
                        setCurrentUser(user)
                    }
                }
            } catch (error) {
                saveAuth(undefined)
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    if(!showSmsInput){
        formik.values.smsCode = '999999'
    }
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>התחברות לאזור אישי</h1>
                {/*<div className='text-gray-400 fw-bold fs-4'>*/}
                {/*  New Here?{' '}*/}
                {/*  <Link to='/auth/registration' className='link-primary fw-bolder'>*/}
                {/*    Create an Account*/}
                {/*  </Link>*/}
                {/*</div>*/}
            </div>
            {/* begin::Heading */}

            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>
                        יש להזין תעודת זהות על מנת להתחבר.
                        <br/>
                        במידה ואינך רשום יש ליצור קשר עם מנהל המערכת.
                    </div>
                </div>
            )}

            {/* begin::Form group */}
            <div className={`fv-row mb-10 ${ showSmsInput ? 'hidden' : ''} `}>
                <label className='form-label fs-6 fw-bolder text-dark'>תעודת זהות</label>
                <input
                    placeholder='הזן תעודת זהות'
                    {...formik.getFieldProps('registerId')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.registerId && formik.errors.registerId},
                        {
                            'is-valid': formik.touched.registerId && !formik.errors.registerId,
                        }
                    )}
                    type='text'
                    name='registerId'
                    autoComplete='off'
                />
                {formik.touched.registerId && formik.errors.registerId && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.registerId}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}

            <div className={`fv-row mb-10  ${ showSmsInput ? '' : 'hidden'} `}>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>קוד אימות</label>
                        {/* end::Label */}
                        {/* begin::Link */}
                        {/*<Link*/}
                        {/*  to='/auth/forgot-password'*/}
                        {/*  className='link-primary fs-6 fw-bolder'*/}
                        {/*  style={{marginLeft: '5px'}}*/}
                        {/*>*/}
                        {/*  Forgot Password ?*/}
                        {/*</Link>*/}
                        {/* end::Link */}
                    </div>
                </div>
                <input
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('smsCode')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.smsCode && formik.errors.smsCode,
                        },
                        {
                            'is-valid': formik.touched.smsCode && !formik.errors.smsCode,
                        }
                    )}
                />
                {formik.touched.smsCode && formik.errors.smsCode && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.smsCode}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    // disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>המשך</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              אנא המתן...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>

                {/*  /!* begin::Separator *!/*/}
                {/*  <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>*/}
                {/*  /!* end::Separator *!/*/}

                {/*  /!* begin::Google link *!/*/}
                {/*  <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
                {/*    <img*/}
                {/*      alt='Logo'*/}
                {/*      src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
                {/*      className='h-20px me-3'*/}
                {/*    />*/}
                {/*    Continue with Google*/}
                {/*  </a>*/}
                {/*  /!* end::Google link *!/*/}

                {/*  /!* begin::Google link *!/*/}
                {/*  <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
                {/*    <img*/}
                {/*      alt='Logo'*/}
                {/*      src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}*/}
                {/*      className='h-20px me-3'*/}
                {/*    />*/}
                {/*    Continue with Facebook*/}
                {/*  </a>*/}
                {/*  /!* end::Google link *!/*/}

                {/*  /!* begin::Google link *!/*/}
                {/*  <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>*/}
                {/*    <img*/}
                {/*      alt='Logo'*/}
                {/*      src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
                {/*      className='h-20px me-3'*/}
                {/*    />*/}
                {/*    Continue with Apple*/}
                {/*  </a>*/}
                {/*  /!* end::Google link *!/*/}
            </div>
            {/* end::Action */}
        </form>
    )
}
