/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMainExternal() {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const [title, setTitle] = useState('')
    useEffect(() => {
        setTitle(currentUser?.role == 10 ? intl.formatMessage({id: 'MENU.PERSONAL'}) : intl.formatMessage({id: 'MENU.DASHBOARD'}));
    },[])



    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={title}
                fontIcon='bi-app-indicator'
            />
            <div className='menu-item'>

            </div>
        </>
    )
}
