import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL // TODO: change to production

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verifytoken`
export const LOGIN_URL = `${API_URL}/login`
export const CCSCORE_URL = `${API_URL}/getcccreditscore`
export const REGISTER_ID_URL = `${API_URL}/checkregisterid`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_LOAN = `${API_URL}/getloan`
export const GET_LOAN_DOCS = `${API_URL}/getloandocs`

// Server should return AuthModel
export function login(registerId: string, smsCode: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    registerId,
    smsCode,
  })
}
export function ccscorecheck(registerId: string, firstName: string, lastName: string, agentId: any, phone: string) {
  return axios.post<any>(CCSCORE_URL, {
    registerId,
    firstName,
    lastName,
    agentId,
    phone
  })
}

export function register_id_check(registerId: string) {
  return axios.post<any>(REGISTER_ID_URL, {
    registerId,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    auth_key: token,
  })
}

export function getLoanByToken(token: string) {
  return axios.post<any>(GET_LOAN, {
    auth_key: token,
  })
}


export function getLoanDocs(token: string,loan_id:number) {
  return axios.post<any>(GET_LOAN_DOCS, {
    auth_key: token,
    loan_id: loan_id,
  })
}
